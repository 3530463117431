<template>
  <va-modal v-model="state.isOpen" hide-default-actions :title="title" size="large">
    <span class="d-flex mb-2">{{ content }}</span>
    <template #footer>
      <va-button class="mr-1" @click="confirm">
        {{ confirmText }}
      </va-button>
    </template>
  </va-modal>
</template>
  
  <script setup>
  import { reactive, watch } from 'vue';
  import { VaButton, VaModal } from 'vuestic-ui';
  
  const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    confirmText: {
      type: String,
      required: true
    }
  });
  
  const emit = defineEmits(['confirm']);
  const state = reactive({
    isOpen: props.isOpen
  });
  
  // Watch the `isOpen` prop to sync with state
  watch(() => props.isOpen, (newVal) => {
    state.isOpen = newVal;
  });
  
  const confirm = () => {
    state.isOpen = false;
    emit('confirm');
  };
  </script>
  
  <style scoped>
  .d-flex {
    display: flex;
  }
  </style>
  