import { App } from 'vue';
import http from '@/api/http';

import { AuthClient } from './authClient'
import axiosPlugin from './plugins/axios'

/**
 * Instance of our Authentication client.
 */
export const authClient = new AuthClient();

/**
 * VueJS integration of our Auth client.
 */
export const authInstaller = {
  install(app: App) {
    axiosPlugin.install(authClient, http);

    app.config.globalProperties.$auth = authClient;
    app.provide('auth', app.config.globalProperties.$auth)
  }
};
