<template>
  <div class="spinner-container">
    <va-progress-circle class="spinner" indeterminate />
  </div>
</template>

<style scoped>
.spinner-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
