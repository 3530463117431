<template>
  <div class="error-layout">
    <div class="error-layout__content">
      <main-logo height="70" class="main-logo" />
      <router-view class="errors" />
    </div>

    <div class="error-wallpaper">
      <error-wallpaper :color="wallpaperColor"/>
    </div>
  </div>
</template>

<script>
import MainLogo from "@/components/main-logo";
import ErrorWallpaper from "@/components/ErrorWallpaper";

export default {
  name: "ErrorLayout",
  components: { MainLogo, ErrorWallpaper },
  computed: {
    wallpaperColor () {
      // return this.contextConfig.invertedColor ? this.$themes.dark : '#e4ff32'
      return 'var(--va-primary)'
    },
  }
};
</script>

<style lang="scss">
.error-layout {
  background: white;
  min-height: 100vh;
  text-align: center;
  min-height: 100vh; /* minimum height = screen height */
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    margin-bottom: 20%;

    .main-logo {
      margin-bottom: 50px;
    }
  }

  .error-wallpaper {
    position: absolute;
    bottom: 0;
    left: 1rem;
    width: 30%;
    height: 20%;
  }

}
</style>
