<template>
  <va-dropdown
    class="profile-dropdown"
    v-model="isShown"
    boundary-body
    position="bottom"
    :offset="[0, 13]"
  >
    <template #anchor>
      <span class="profile-dropdown__anchor">
        <slot />
        <va-icon
          class="px-2"
          :name="isShown ? 'angle_up' : 'angle_down'"
          :color="theme.primary"
        />
      </span>
    </template>
    <va-dropdown-content class="profile-dropdown__content  pl-4 pr-4 pt-2 pb-2">
      <div
        class="profile-dropdown__item row align--center pt-1 pb-1 mt-2 mb-2"
        @click="logout()"
      >
        <va-icon name="logout" class="profile-dropdown__icon" />
        <span class="dropdown-item__text">
          {{ $t(`user.logout`) }}
        </span>
      </div>
    </va-dropdown-content>
  </va-dropdown>
</template>

<script>
import { useGlobalConfig } from "vuestic-ui";

export default {
  name: "profile-section",
  data() {
    return {
      isShown: false
    };
  },

  methods: {
    async logout() {
      await this.$auth.logout();
    }
  },

  computed: {
    theme() {
      return useGlobalConfig().getGlobalConfig();
    }
  }
};
</script>

<style lang="scss">
.profile-dropdown {
  cursor: pointer;

  .va-dropdown-popper__anchor {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    width: 8rem;
  }

  &__item {
    font-family: var(--var-font-family);
    display: block;
    cursor: pointer;
    color: var(--va-gray);

    &:hover,
    &:active {
      color: var(--va-primary);
    }
  }

  &__icon {
    margin-right: 0.5rem;
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
