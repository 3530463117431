import { UserManager } from "oidc-client-ts";
import { Emitter, EventListener } from "event-emitter";
import createEventEmitter from "event-emitter";

import config from "@/config"
import { UserInfo as IUserInfo } from "@/types";

/**
 * Page to which user is redirected after sign-in.
 */
export const CALLBACK_URL = "/auth/callback";

/**
 * Represents information concerning logged-in user.
 */
export class UserInfo implements IUserInfo {
  name = "";
  email: string|null = "";
}

/**
 * Joins two path segments, adding separator if required.
 * @param left Left part of the path
 * @param right Right part of the path
 * @returns Joined path
 */
function joinPath(left: string|null, right: string|null) {
  if (left === null) {
    return "" + (right ?? "")
  } else if (right === null) {
    return left;
  } else if (!left.endsWith('/') && !right.startsWith('/')) {
    return left + '/' + right;
  }

  return left + right;
}

export class AuthClient {
  _mgr: UserManager;
  _events: Emitter;

  loading = false;

  /**
   * Constructs a new authentication client.
   */
  constructor() {
    this._events = createEventEmitter();
    this._mgr = new UserManager({
      authority: config.auth.authority,
      client_id: config.auth.clientId, // eslint-disable-line
      redirect_uri: joinPath(config.baseUrl, CALLBACK_URL), // eslint-disable-line
      scope: "openid profile email " + config.auth.apiScope
    });

    this.loading = false;
  }

  /**
   * Retrieves information on signed-in user.
   *
   * @returns Information on signed-in user.
   */
  async getUser(): Promise<UserInfo | null> {
    const usr = await this._mgr.getUser();
    if (usr === null) {
      return null;
    }

    return {
      email: usr.profile.email ?? null,
      name: usr.profile.name ?? usr.profile.nickname ?? "unknown"
    }
  }

  /**
   * Retrieves the access token to be used to authenticate as our user against
   * remote APIs.
   *
   * @returns Access token
   */
  async getToken(): Promise<string> {
    const user = await this._mgr.getUser();
    if (!user) {
      throw new Error("no signed-in user: can't retrieve access token")
    }

    return user.access_token;
  }

  /**
   * Initializes the authentication client, potentially redirecting the user
   * to the sign-in page if they are not signed-in.
   * @returns Promise that resolves on signed-in user.
   */
  async initialize(): Promise<UserInfo | null> {
    const user = await this.getUser();
    if (user !== null) {
      return user;
    }

    if (window.location.pathname === CALLBACK_URL) {
      await this._mgr.signinCallback();

       // Clear authentication data from url by removing url fragment.
       history.pushState('', document.title, window.location.pathname);

       // Retrieve the original URL from local storage
       const originalUrl = localStorage.getItem('original_url');
       localStorage.removeItem('original_url');
 
       if (originalUrl) {
         window.location.href = originalUrl;
         return null;
       }
      // Clear authentication data from url by removing url fragment.
      history.pushState('', document.title, window.location.pathname);

      return await this.getUser();
    }
    // Store the original URL before redirecting
    localStorage.setItem('original_url', window.location.href);
    await this._mgr.signinRedirect();
    return null;
  }

  /**
   * Log the current-user out, which will cause them to be redirected to the
   * sign-in page again.
   */
  logout(): Promise<void> {
    return this._mgr.signoutRedirect();
  }


  /**
   * Binds to an event form the authentication client.
   * @param type Event type
   * @param listener Listener
   */
  on(type: string, listener: EventListener) {
    this._events.on(type, listener);
  }

  /**
   * Binds to a single event form the authentication client.
   * @param type Event type
   * @param listener Listener
   */
  once(type: string, listener: EventListener) {
    this._events.once(type, listener);
  }

  /**
   * Removes an event listener for a designated event type from authentication
   * client.
   * @param type Event type
   * @param listener Listener
   */
  off(type: string, listener: EventListener) {
    this._events.off(type, listener);
  }

  /**
   * Informs listeners our loading state has changed.
   *
   * @param loading Whether we are currently loading information.
   */
  _setLoading(loading: boolean) {
    if (this.loading !== loading) {
      this.loading = loading;
      this._events.emit("loading", loading);
    }
  }
}
