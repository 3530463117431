<template>
  <div>Loading...</div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

onMounted(() => {
  // Log the URL parameters
  console.log("Auth callback parameters:", route.query);

  // Redirect to the users page after logging
  router.push({ name: 'users' });
});
</script>