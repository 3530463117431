<template>
  <loading-spinner v-if="loading" />
  <router-view v-else />
  <!--<va-modal
    v-model="handleSessionExpired"
    :title="$t('auth.sessionExpired.title')"
    :message="$t('auth.sessionExpired.message')"
    @ok="acceptSessionRenew()"
    @cancel="cancelSessionRenew()"
  />-->
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner
  },

  data() {
    return {
      user: null,
      roles: [],
      permissions: [],
      loading: false,
    };
  },

  async created() {
    this.loading = true;

    this.user = await this.$auth.getUser();
    await this.$store.dispatch("auth/setUser", this.user);
    await this.$store.dispatch("auth/fetchRolesAndPermissions");

    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "~@/sass/main.scss";

#app {
  font-family: "Source Sans Pro", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
  background: var(--va-background);
}
</style>
