import http from "@/api/http";

// ORGANIZATIONS

/**
 * Create organization
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callCreateOrganization = async payload => {
  return await http.post('/api/identityhub/organizations', payload);
};

/**
 * Fetch organizations
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchOrganizations = async payload => {
  return await http.get("/api/identityhub/organizations", payload);
};

/**
 * Get organization
 * @param {string} organizationId
 * @return {Promise<Object>}
 */
export const callFetchOrganization = async organizationId => {
  return await http.get(`/api/identityhub/organizations/${organizationId}`);
};

/**
 * Delete organization
 * @param {string} organizationId
 * @return {Promise<Object>}
 */
export const callDeleteOrganization = async organizationId => {
  return await http.delete(`/api/identityhub/organizations/${organizationId}`);
};

/**
 * Fetch organization members
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchOrganizationMembers  = async (organizationId, payload) => {
  return await http.get(
    `/api/identityhub/organizations/${organizationId}/members`,
    payload);
};
/**
 * Delete user from organization
 * @param {string} organizationId - The organization ID
 * @param {string} memberId - The member ID
 * @return {Promise<Object>}
 */
export const callDeleteUserFromOrganization = async (organizationId, memberId) => {
  const url = `/api/identityhub/organizations/${organizationId}/members/${memberId}`;
  return await http.delete(url);
};

// USERS

/**
 * Fetch users
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchUsers = async payload => {
  return await http.get("/api/identityhub/users", payload);
};

/**
 * Get user
 * @param {string} userId
 * @return {Promise<Object>}
 */
export const callFetchUser = async userId => {
  return await http.get(`/api/identityhub/users/${userId}`);
};

/**
 * Delete user
 * @param {string} userId
 * @return {Promise<Object>}
 */
export const callDeleteUser = async userId => {
  return await http.delete(`/api/identityhub/users/${userId}`);
};

// Roles

/**
 * Fetch roles
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchRoles = async payload => {
  return await http.get("/api/identityhub/roles", payload);
};
/**
 * Delete user role
 * @param {string} orgId - The organization ID
 * @param {string} userId - The user ID
 * @param {string} roleId - The role ID
 * @return {Promise<Object>}
 */
export const callDeleteUserRole = async (orgId, userId, roleId) => {
  const url = `/api/identityhub/organizations/${orgId}/members/${userId}/roles/${roleId}`;
  return await http.delete(url);
};

/**
 * Add user role
 * @param {string} orgId - The organization ID
 * @param {string} userId - The user ID
 * @param {string} roleId - The role ID
 * @return {Promise<Object>}
 */
export const callAddUserRole = async (orgId, userId, roleId) => {
  const url = `/api/identityhub/organizations/${orgId}/members/${userId}/roles`;
  const data = {
    roleId: roleId
  };
  return await http.post(url, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};
// USERS

/**
 * Fetch user authentication methods
 * @param {string} userId
 * @return {Promise<Object>}
 */
export const callFetchUserAuthMethods = async userId => {
  const url = `/api/identityhub/users/${userId}/auth-methods`;
  return await http.get(url);
};
/**
 * Delete user auth
 * @param {string} userId - The user ID
 * @param {string} methodId  - The auth ID
 * @return {Promise<Object>}
 */
export const callDeleteUserAuth = async ( userId, methodId) => {
  const url = `/api/identityhub/users/${userId}/auth-methods/${methodId}`;
  return await http.delete(url);
};

// INVITES

/**
 * Fetch invites
 * @param {Object} payload
 * @return {Promise<Object>}
 */
export const callFetchInvites = async payload => {
  return await http.get("/api/identityhub/invites", payload);
};

/**
 * Get invite
 * @param {string} inviteId
 * @return {Promise<Object>}
 */
export const callFetchInvite = async inviteId => {
  return await http.get(`/api/identityhub/invites/${inviteId}`);
};

/**
 * Delete invite
 * @param {string} inviteId
 * @return {Promise<Object>}
 */
export const callDeleteInvite = async inviteId => {
  return await http.delete(`/api/identityhub/invites/${inviteId}`);
};
/**
 * Create and return the link to the organization dedicated page
 * @param {string} organizationId
 * @returns {string}
 */
export const organizationLink = organizationId => {
  if (!organizationId) {
    return;
  }
  return `/admin/identity/organizations/${organizationId}`;
};

/**
 * Create and return the link to the user dedicated page
 * @param {string} userId
 * @returns {string}
 */
export const userLink = userId => {
  if (!userId) {
    return;
  }
  return `/admin/identity/users/${userId}`;
};

/**
 * Create and return the link to the invite dedicated page
 * @param {string} inviteId
 * @returns {string}
 */
export const inviteLink = inviteId => {
  if (!inviteId) {
    return;
  }
  return `/admin/identity/invites/${inviteId}`;
};

/**
 * Create and return the link to the page related to given identity entity.
 * @param {Object} identity Generic identity entity object
 * @param {string} identity.id Identifier of the identity entity.
 * @param {string} identity.type Type of identity.
 * @returns {string} Link to the page associated to the identity entity.
 */
export const identityLink = identity => {
  if (!identity) {
    return;
  }

  const idType = identity.type?.toLowerCase();

  if (idType === "organization") {
    return organizationLink(identity.id);
  }

  if (idType === "invite") {
    return inviteLink(identity.id);
  }

  return userLink(identity.id);
}

/**
 * Add a member to an organization
 * @param {string} organizationId - The ID of the organization
 * @param {Object} memberData - The data for the new member
 * @param {string} memberData.email - The email of the new member
 * @param {string} memberData.senderName - The sender's name
 * @param {string} memberData.locale - The locale of the new member
 * @param {Array<string>} memberData.roles - The roles to assign to the new member
 * @return {Promise<Object>} - The response from the server
 */
export const callAddMember = async (organizationId, memberData) => {
  const url = `/api/identityhub/organizations/${organizationId}/members`;
  try {
    const response = await http.post(url, memberData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding member:', error);
    throw error;
  }
};