import {
  callFetchAssignment,
  callPatchAssignment,
  callFetchEntitlement,
  callPatchEntitlement,
  callFetchProduct,
  callFetchProducts,
} from "@/api/licensing";

export default {
  namespaced: true,
  state: {
    license: {},
    licenseEdit: {},
    entitlement: {},
    entitlementEdit: {},
    products: [],
    productEdit: null,
  },
  mutations: {
    SET_PRODUCT_EDIT(state, product) {
      state.productEdit = product;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    // ASSIGNMENTS

    /**
     * @param {Object} state
     * @param {Object} assignment
     */
    INIT_ASSIGNMENT_AND_EDIT(state, assignment) {
      state.assignment = JSON.parse(JSON.stringify(assignment));
      state.assignmentEdit = JSON.parse(JSON.stringify(assignment));
    },
    UPDATE_ASSIGNMENT_ASSIGNEE(state, assignee) {
      state.assignment.assignee = JSON.parse(JSON.stringify(assignee));
      state.assignmentEdit.assignee = JSON.parse(JSON.stringify(assignee));
    },
    /**
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string} payload.value
     */
    UPDATE_ASSIGNMENT_EDIT(state, { key, value }) {
      state.assignmentEdit[key] = value;
    },
    /**
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string} payload.value
     */
    UPDATE_ASSIGNMENT_EDIT_ASSIGNEE(state, { value }) {
      state.assignmentEdit.assigneeId = value;
    },
    RESET_ASSIGNMENT_EDIT(state) {
      state.assignmentEdit = JSON.parse(JSON.stringify(state.license));
    },

    // ENTITLEMENTS

    /**
     * @param {Object} state
     * @param {Object} entitlement
     */
    INIT_ENTITLEMENT(state, entitlement) {
      state.entitlement = JSON.parse(JSON.stringify(entitlement));
    },
    /**
     * @param {Object} state
     * @param {Object} entitlement
     */
    INIT_ENTITLEMENT_AND_EDIT(state, entitlement) {
      state.entitlement = JSON.parse(JSON.stringify(entitlement));
      state.entitlementEdit = JSON.parse(JSON.stringify(entitlement));
    },
    /**
     * @param {Object} state
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string} payload.value
     */
    UPDATE_ENTITLEMENT_EDIT(state, { key, value }) {
      state.entitlementEdit[key] = value;
    },
    RESET_ENTITLEMENT_EDIT(state) {
      state.entitlementEdit = JSON.parse(JSON.stringify(state.entitlement));
    },

    ADD_PRODUCT(state, product) {
      state.products.push(product);
    }
  },
  actions: {
    // PRODUCTS

    /**
     * Retrieves information regarding designated product.
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} obj
     * @param {string} obj.productId Id of the product for which to get info.
     */
    async getProduct({ commit, state }, productId) {
      try {
        const existing = state.products.find(p => p.id === productId);
        if (existing) {
          commit('SET_PRODUCT_EDIT', existing);
          return existing;
        }

        const resp = await callFetchProduct(productId);
        commit("ADD_PRODUCT", resp.data);
        commit('SET_PRODUCT_EDIT', resp.data);

        return resp.data;
      } catch (e) {
        console.error(e?.response?.data);
      }
    },
    async fetchProducts({ commit }) {
      try {
        const resp = await callFetchProducts();  
        commit("SET_PRODUCTS", resp.data.items);
      } catch (e) {
        console.error("Failed to fetch products:", e?.response?.data);
      }
    },
    // LICENSES

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} assignment
     */
    initAssignment({ commit }, assignment) {
      commit("INIT_LICENSE_AND_EDIT", assignment);
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} license
     */
    async fetchAssignment({ commit }, assignmentId) {
      try {
        const resp = await callFetchAssignment(assignmentId);
        commit("INIT_ASSIGNMENT_AND_EDIT", resp.data);
      } catch (e) {
        this.handleError(e?.response?.data);
      }
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} assignmentId
     */
    async patchAssignment({ commit }, { assignmentId, payload }) {
      try {
        const resp = await callPatchAssignment(assignmentId, payload);
        commit("INIT_ASSIGNMENT_AND_EDIT", resp.data);
      } catch (e) {
        this.handleError(e?.response?.data);

        // Reset the assignmentEdit with previous data as the update didn't work
        commit("RESET_ASSIGNMENT_EDIT");
      }
    },

    resetAssignmentEdit({ commit }) {
      commit("RESET_ASSIGNMENT_EDIT");
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} payload
     * @param {string} payload.assignmentId
     * @param {string} payload.assigneeId
     * @param {'User'|'Organization'|'Group'} payload.assignee.type - Group not supported atm
     */
    async updateAssignmentAssignee({ commit }, { assignmentId, assigneeId}) {
      try {
        const response = await  callPatchAssignment(assignmentId, {
          assigneeId,
        });

        commit("UPDATE_ASSIGNMENT_ASSIGNEE", response.data.assignee);
      } catch (e) {
        this.handleError(e?.response?.data);
      }
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string} payload.value
     */
    updateLicenseEditField({ commit }, payload) {
      commit("UPDATE_LICENSE_EDIT", payload);
    },

    /**
     * TO DO : create a recursive function to avoid duplication with updateLicenseEditField
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string} payload.value
     */
    updateAssignmentLicenseEditField({ commit }, payload) {
      commit("UPDATE_LICENSE_EDIT_ASSIGNMENT", payload);
    },

    // ENTITLEMENTS

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} entitlement
     */
    initEntitlement({ commit }, entitlement) {
      commit("INIT_ENTITLEMENT_AND_EDIT", entitlement);
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {string} entitlementId
     */
    async setEntitlement({ commit }, entitlementId) {
      try {
        const resp = await callFetchEntitlement(entitlementId);
        commit("INIT_ENTITLEMENT_AND_EDIT", resp.data);
      } catch (e) {
        this.handleError(e?.response?.data);
      }
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} storeObj.state
     * @param {string} entitlementId
     */
    async fetchEntitlementParent({ commit, state }) {
      try {
        const resp = await callFetchEntitlement(state.license?.entitlementId);
        commit("INIT_ENTITLEMENT", resp.data);
      } catch (e) {
        this.handleError(e?.response?.data);
      }
    },

    resetEntitlementEdit({ commit }) {
      commit("RESET_ENTITLEMENT_EDIT");
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} payload
     * @param {string} payload.key
     * @param {string} payload.value
     */
    updateEntitlementEditField({ commit }, payload) {
      commit("UPDATE_ENTITLEMENT_EDIT", payload);
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} obj
     * @param {string} obj.entitlementId
     * @param {Object} obj.payload
     */
    async patchEntitlement({ commit }, { entitlementId, payload }) {
      try {
        const resp = await callPatchEntitlement(entitlementId, payload);
        commit("INIT_ENTITLEMENT_AND_EDIT", resp.data);
      } catch (e) {
        // Reset the entitlementEdit with license as the update didn't work
        commit("RESET_ENTITLEMENT_EDIT");

        this.handleError(e?.response?.data);
      }
    },

    
   
  }
};
