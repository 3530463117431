/**
 * Backoffice frontend configuration.
 *
 * We declare our config object, which will be bundled into the final webapp
 * on the serve/build steps.
 */

export default {
  baseUrl: process.env.VUE_APP_BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  auth: {
    authority: process.env.VUE_APP_AUTH_AUTHORITY_URI,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    apiScope: process.env.VUE_APP_AUTH_API_SCOPE,
  },
  redeemUrl: process.env.VUE_APP_REDEEM_URL,
};
