import { createStore } from "vuex";
import auth from "@/store/auth.js";
import identity from "@/store/identity.js";
import licensing from "@/store/licensing.js";
import sidebar from "@/store/sidebar.js";

export default createStore({
  modules: {
    auth,
    identity,
    licensing,
    sidebar
  }
});
