export default {
  root: {
    name: "/",
    displayName: "navigationRoutes.home"
  },
  routes: [
    {
      name: "users",
      displayName: "menu.users",
      meta: {
        icon: "material-icons-fingerprint",
        requiredPermissions: ["read:user"],
        subViews: ["user"]
      }
    },
    {
      name: "organizations",
      displayName: "menu.organizations",
      meta: {
        icon: "material-icons-corporate_fare",
        requiredPermissions: ["read:organization"],
        subViews: ["organization"]
      }
    },
    {
      name: "invites",
      displayName: "menu.invites",
      meta: {
        icon: "material-icons-card_membership",
        requiredPermissions: ["read:invite"],
        subViews: ["invites"]
      }
    },
    {
      name: "entitlements",
      displayName: "menu.entitlements",
      meta: {
        icon: "material-icons-card_membership",
        requiredPermissions: ["read:entitlements", "read:products"],
        subViews: ["entitlement"]
      }
    },
    {
      name: "templates",
      displayName: "menu.templates",
      meta: {
        icon: "material-icons-card_membership",
        requiredPermissions: ["read:templates", "read:products"],
        subViews: ["templates","template"]
      }
    },
    {
      name: "products",
      displayName: "menu.products",
      meta: {
        icon: "material-icons-card_membership",
        requiredPermissions: ["read:products"],
        subViews: ["products","product"]
      }
    }
  ]
};
