import { App } from 'vue';
import { useGlobalConfig } from 'vuestic-ui'

export default {
  install(app: App) {

    function toast(message: Error|string, color: string) {
      message = message as string ?? (message as Error)?.message;

      if (app.config.globalProperties.$vaToast) {
        app.config.globalProperties.$vaToast.init({
          message,
          color,
        });
      } else {
        console.log("failed to show toast, message:", message);
      }
    }

    const theme = useGlobalConfig().getGlobalConfig().colors;
    const colorInfo  = theme?.info ?? 'blue';
    const colorError = theme?.danger ?? 'red';
    const colorWarning = theme?.warning ?? 'orange';
  

    app.config.globalProperties.$toasts = {
      info: (message: Error|string) => toast(message, colorInfo),
      information: (message: Error|string) => toast(message, colorInfo),
      warn: (message: Error|string) => toast(message, colorWarning),
      warning: (message: Error|string) => toast(message, colorWarning),
      err: (message: Error|string) => toast(message, colorError),
      error: (message: Error|string) => toast(message, colorError),
    }
  }
}
