import { AuthClient } from '../authClient'
import { AxiosInstance } from 'axios'

export default {
  /**
   * Injects a response interceptor into given axios instance that will handle
   * access token injection for an outgoing request.
   *
   * @param authClient Authentication client
   * @param httpClient Axios instance to add interceptor into
   */
  install(authClient: AuthClient, httpClient: AxiosInstance) {
    httpClient.interceptors.request.use(async req => {
      // Only inject our token if the request is meant for our API.
      const url = (req.baseURL ?? "") + (req.url ?? "");
      if (!url.startsWith(process.env.VUE_APP_API_URL)) {
        return req;
      }

      const token = await authClient.getToken();

      req.headers["Authorization"] = "Bearer " + token;

      return req;
    });
  },
};
