<template>
  <va-sidebar
    :width="width"
    :minimized="minimized"
    :minimizedWidth="minimizedWidth"
  >
    <menu-minimized v-if="minimized" :items="routes" />
    <menu-accordion v-else :items="routes" />
  </va-sidebar>
</template>

<script>
import { useGlobalConfig } from "vuestic-ui";
import { isProxy, toRaw } from "vue";
import { useStore } from "vuex";
import MenuAccordion from "./menu/MenuAccordion.vue";
import MenuMinimized from "./menu/MenuMinimized.vue";
import NavigationRoutes from "./NavigationRoutes";

export default {
  name: "app-sidebar",
  components: {
    MenuAccordion,
    MenuMinimized
  },

  setup() {
    return {
      store: useStore(),
    }
  },

  props: {
    width: { type: String, default: "16rem" },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: String,
      default: undefined
    }
  },

  methods: {
    asRaw(data) {
      return isProxy(data) ? toRaw(data) : data;
    },

    hasPermissions(requested) {
      if (!requested) {
        return true;
      }

      // A global admin should have global access. The backoffice API does not
      // take the responsability of returning every possible permission (as it
      // would be complicated and expensive performance wise), but rather
      // returns a bool that says "user has all permissions". In such a case,
      // we bypass permission check.
      const hasGlobalAccess = this.asRaw(this.store.state.auth.hasGlobalAccess);
      if (hasGlobalAccess) {
        return true;
      }

      const permissions = this.asRaw(this.store.state.auth.permissions);
      if (!permissions) {
        return false;
      }

      if (Array.isArray(requested)) {
        return permissions.some(p => requested.includes(p));
      }

      return permissions.includes(requested);
    },
  },

  computed: {
    routes() {
      return NavigationRoutes.routes.filter(
        route => this.hasPermissions(route.meta?.requiredPermissions)
      );
    },

    computedClass() {
      return {
        "app-sidebar--minimized": this.minimized
      };
    },

    colors() {
      return useGlobalConfig().getGlobalConfig().colors;
    }
  }
};
</script>

<style lang="scss">
.va-sidebar {
  .va-collapse__body {
    margin-top: 0 !important;
  }

  &__menu {
    padding: 2rem 0;
    &__inner {
      padding-bottom: 8rem;
    }
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
  flex-shrink: 0;
}
</style>
