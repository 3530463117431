import { fetchRolesAndPermissions } from "@/api/agent";

export default {
  namespaced: true,
  state: {
    user: {},
    roles: [],
    permissions: [],
    hasGlobalAccess: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    SET_ROLES(state, roles) {
      state.roles = roles;
    },

    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },

    SET_GLOBAL_ACCESS(state, globalAccess) {
      state.hasGlobalAccess = globalAccess;
    },
  },
  actions: {
    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {Object} user
     */
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },

    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     */
    async fetchRolesAndPermissions({ commit }) {
      try {
        const resp = await fetchRolesAndPermissions();
        commit("SET_ROLES", resp.data.roles);
        commit("SET_PERMISSIONS", resp.data.permissions ?? []);
        commit("SET_GLOBAL_ACCESS", resp.data.grantedAllPermissions ?? false)
      } catch (e) {
        this.handleError(e);
      }
    },
  }
};
