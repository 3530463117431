import axios from "axios";
import config from "@/config"

const client = axios.create({
  baseURL: config.apiUrl,
  timeout: 5000,
  withCredentials: true
});

export default client;
