import { App } from "vue";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw
} from "vue-router";
import AppLayout from "@/layout/app-layout.vue";
import ErrorsLayout from "@/layout/errors-layout.vue";
import { CALLBACK_URL } from "../services/auth/authClient"
import AuthCallback from "@/pages/auth/AuthCallback.vue"; 

import RouteViewComponent from "./route-view.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "not-found" }
  },
  {
    path: "/",
    redirect: { name: "users" }
  },
  {
    path: CALLBACK_URL,
    name: "auth-callback",
    component: AuthCallback
  },
  {
    name: "admin",
    path: "/admin",
    component: AppLayout,
    children: [
      {
        name: "identity",
        path: "identity",
        component: RouteViewComponent,
        children: [
          {
            name: "users",
            path: "users",
            component: () => import("@/pages/admin/identity/UsersPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "IdentityReader"
            }
          },
          {
            name: "user",
            path: "users/:id",
            component: () => import("@/pages/admin/identity/UserPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "IdentityReader"
            }
          },
          {
            name: "organizations",
            path: "organizations",
            component: () =>
              import("@/pages/admin/identity/OrganizationsPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "IdentityReader"
            }
          },
          {
            name: "organization",
            path: "organizations/:id",
            component: () =>
              import("@/pages/admin/identity/OrganizationPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "IdentityReader"
            }
          },
          {
            name: "invites",
            path: "invites",
            component: () =>
              import("@/pages/admin/identity/InvitesPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "IdentityReader"
            }
          },
          {
            name: "invite",
            path: "invites/:id",
            component: () =>
              import("@/pages/admin/identity/InvitePage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "IdentityReader"
            }
          }
          ,
          {
            name: "invite",
            path: "invites/:id",
            component: () =>
              import("@/pages/admin/identity/InvitePage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "IdentityReader"
            }
          }
        ]
      },
      {
        name: "licensing",
        path: "licensing",
        component: RouteViewComponent,
        children: [
          {
            name: "entitlements",
            path: "entitlements",
            component: () =>
              import("@/pages/admin/licensing/EntitlementsPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "LicensingReader"
            }
          },
          {
            name: "entitlement",
            path: "entitlements/:id",
            component: () =>
              import("@/pages/admin/licensing/EntitlementPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "LicensingReader"
            }
          }
        ]
      },
      {
        name: "Products",
        path: "products",
        component: RouteViewComponent,
        children: [
          {
            name: "templates",
            path: "templates",
            component: () =>
              import("@/pages/admin/product/TemplatesPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "LicensingReader"
            }
          },
          {
            name: "template",
            path: "templates/:id",
            component: () =>
              import("@/pages/admin/product/TemplatePage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "GlobalAdmin"
            }
          },
          {
            name: "products",
            path: "",
            component: () =>
              import("@/pages/admin/product/ProductsPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "GlobalAdmin"
            }
          },
          {
            name: "product",
            path: ":id",
            component: () =>
              import("@/pages/admin/product/ProductPage.vue"),
            meta: {
              requiresAuth: true,
              requiredRole: "GlobalAdmin"
            }
          },
          
        ]
      }
    ]
  },
  {
    path: "/errors",
    component: ErrorsLayout,
    children: [
      {
        name: "not-found",
        path: "not-found",
        component: () => import("@/pages/errors/NotFound.vue")
      },
      {
        name: "server-issue",
        path: "server-issue",
        component: () => import("@/pages/errors/ServerIssue.vue")
      },
      {
        name: "not-authorized",
        path: "not-authorized",
        component: () => import("@/pages/errors/NotAuthorized.vue")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
});

export default {
  // We return a plugin so that we may retrieve the 'app' instance to be used
  // within our router.
  install(app: App) {
    router.install(app);

    // Hook into webpack's chunk loader to attempt re-login on failed chunk loads,
    // as it is likely because of an expired user session.
    // eslint-disable-next-line
    const loadChunk = __webpack_chunk_load__;
    // eslint-disable-next-line
    __webpack_chunk_load__ = chunkId => {
      // There is something weird about the types of __webpack_chunk_load__.
      // The types are different that the one used and from the documentation.
      // eslint-disable-next-line
      // @ts-ignore
      return loadChunk(chunkId);
    };

    //async function requireAuth(
    //  to: RouteLocationNormalized,
    //  from: RouteLocationNormalized,
    //  next: NavigationGuardNext
    //) {
    //  const requiresAuth = (to.meta?.requiresAuth ?? false) as boolean;
    //  if (requiresAuth) {
    //    const requiredRole = to.meta?.requiredRole as string;
    //    if (requiredRole) {
    //      if (!(await auth.hasAccess(["GlobalReader", requiredRole]))) {
    //        next("/errors/not-authorized");
    //        return;
    //      }
    //    }
    //  }

    //  next();
    //}

    //router.beforeEach(requireAuth);
  }
};
