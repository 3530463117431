export default {
  namespaced: true,
  state: {
    isMinimized: false
  },
  mutations: {
    SET_SIDEBAR_MINIMIZED(state, isMinimized) {
      state.isMinimized = isMinimized;
    }
  },
  actions: {
    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {boolean} isMinimized
     */
    setSidebarMinimized({ commit }, isMinimized) {
      commit("SET_SIDEBAR_MINIMIZED", isMinimized);
    }
  }
};
