<template>
  <img src="/img/logo_dark.png" :height="height" />
</template>
<script>
export default {
  name: "MainLogo",

  props: {
    height: { type: [Number, String], default: 30 },
  },
};
</script>
