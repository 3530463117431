import {
  callFetchOrganization,
  callFetchUser,
  callFetchInvite
} from "@/api/identity";

export default {
  namespaced: true,
  state: {
    user: {},
    organization: {},
  },
  mutations: {
    /**
     * @param {Object} state
     * @param {Object} user
     */
    SET_USER(state, user) {
      state.user = JSON.parse(JSON.stringify(user));
    },
    /**
     * @param {Object} state
     * @param {Object} user
     */
    SET_INVITE(state, invite) {
      state.user = JSON.parse(JSON.stringify(invite));
    },
    /**
     * @param {Object} state
     * @param {Object} organization
     */
    SET_ORGANIZATION(state, organization) {
      state.organization = JSON.parse(JSON.stringify(organization));
    }
  },
  actions: {
    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {string} userId
     */
    async fetchUser({ commit }, userId) {
      try {
        const resp = await callFetchUser(userId);
        commit("SET_USER", resp.data);
      } catch (e) {
        this.handleError(e);
      }
    },
    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {string} inviteId
     */
    async fetchInvite({ commit }, inviteId) {
      try {
        const resp = await callFetchInvite(inviteId);
        commit("SET_INVITE", resp.data);
      } catch (e) {
        this.handleError(e);
      }
    },
    /**
     * @param {Object} storeObj
     * @param {function(string, ?any, ?Object): void} storeObj.commit
     * @param {string} organizationId
     */
    async fetchOrganization({ commit }, organizationId) {
      try {
        const resp = await callFetchOrganization(organizationId);
        commit("SET_ORGANIZATION", resp.data);
      } catch (e) {
        this.handleError(e);
      }
    }
  }
};
